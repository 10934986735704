
import { defineComponent, reactive, ref, Ref, computed, watch } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useForm } from '@ant-design-vue/use'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { Rule } from 'ant-design-vue/es/form/interface'
import { GlobalDataProps } from '../store/index'
interface RuleFormInstance {
  validate: () => Promise<any>;
}
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    const isLoginLoading = computed(() => store.getters.isOpLoading('login'))
    const isSignupLoading = computed(() => store.getters.isOpLoading('signUp'))
    const router = useRouter()
    const counter = ref(60)
    let timer = 0
    const loginForm = ref() as Ref<RuleFormInstance>
    const form = reactive({
      cellphone: '',
      verifyCode: '',
      password: '',
      passwordCheck: ''
    })
    const codeButtonDisable = computed(() => {
      return !/^1[3-9]\d{9}$/.test(form.cellphone.trim()) || (counter.value < 60)
    })
    const startCounter = () => {
      counter.value--
      timer = window.setInterval(() => {
        counter.value--
      }, 1000)
    }
    watch(counter, (newValue) => {
      if (newValue === 0) {
        clearInterval(timer)
        counter.value = 60
      }
    })
    const cellnumberValidator = (rule: Rule, value: string) => {
      return new Promise((resolve, reject) => {
        const passed =  /^1[3-9]\d{9}$/.test(value.trim())
        setTimeout(() => {
          if (passed) {
            resolve('')
          } else {
            reject('手机号码格式不正确')
          }
        }, 500)
      })
    }
    const rules = reactive({
      cellphone: [
        { required: true, message: '手机号码不能为空', trigger: 'blur' },
        // { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        { asyncValidator: cellnumberValidator, trigger: 'blur' }
      ],
      verifyCode: [
        { required: true, message: '验证码不能为空', trigger: 'blur' }
      ],
      password: [
        { required: true, message: '新密码不能为空', trigger: 'blur' },
        { min: 6, message: '密码至少6位', trigger: 'blur' }
      ],
      passwordCheck: [
        { required: true, message: '密码不能为空', trigger: 'blur' },
        { min: 6, message: '密码至少6位', trigger: 'blur' },
        { validator: (rule: Rule,value: string) => {
          if (value !== form.password) {
            return Promise.reject('两次输入密码不一致')
          }
          return Promise.resolve()
        }, trigger: 'blur' }
      ]
    })

    const { validate, resetFields } = useForm(form, rules)
    const signUp = () => {
      validate().then(() => {
        const payload = {
          phoneNumber: form.cellphone,
          verifyCode: form.verifyCode,
          password: form.password
        }
        store.dispatch('forgetPasswordAndFetch', { data: payload }).then(() => {
          message.success('密码修改成功 2秒后跳转首页')
          setTimeout(() => {
            router.push('/')
          }, 2000)
        })
      })
    }
    const getCode = () => {
      axios.post('/user/genPasswordCode', {phoneNumber: form.cellphone}).then((res) => {
        message.success(`验证码已经发送到您手机上，请注意查收`, 5)
        startCounter()
      }).catch((e) => {
        message.error('验证码发送失败')
      })
    }
    // const getCode = () => {
    //   axios.post('/user/genVerifyCode', {phoneNumber: form.cellphone}).then((res) => {
    //     const verifyCode = res.data.data
    //     message.success(`您的验证码是${verifyCode}，请注意查收`, 10)
    //     startCounter()
    //   }).catch((e) => {
    //     console.log('skkk', e)
    //     message.error('验证码发送失败')
    //   })
    // }
    return {
      form,
      rules,
      loginForm,
      signUp,
      codeButtonDisable,
      getCode,
      counter,
      isLoginLoading,
      isSignupLoading
    }
  }
})
